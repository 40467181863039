@import "../../../mixins.scss";

.item-main-desktop {
  .items-body {
    @include for-desktop-screen {
      margin-top: 50px;
      margin-bottom: 50px;
      justify-content: start;
    }
    @include for-mob-s4 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 245px);
      grid-template-rows: auto;
      gap: 0px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
    @include for-mob-s3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 245px);
      grid-template-rows: auto;
      gap: 0px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
    @include for-mob-s2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 245px);
      grid-template-rows: auto;
      gap: 0px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
    @include for-mob-s1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 245px);
      grid-template-rows: auto;
      column-gap: 20px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }

    @include for-md-1-screen {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }
    @include for-md-2-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }
    @include for-lg1-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }
    @include for-lg2-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }
    @include for-xl-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }
    @include for-xxl-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }

    @include for-mob-screen {
      margin-top: 10px;
      margin-bottom: 40px;
    }

    @include for-xl-1-screen {
      display: grid;
    }

    .pagination {
      direction: ltr;
    }
    .small-display-none {
      @include for-mob-screen {
        display: none;
      }
    }

    .large-display-none {
      height: 350px;
      @include for-desktop-screen {
        display: none;
      }
    }
  }
}
