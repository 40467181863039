.ramadan-page-mob {
    .swiper-container {

        .swiper-button-next,
        .swiper-button-prev {
            border-radius: 50%;
            background: #ffffff9e;
            height: 35px;
            width: 35px;
            color: black;
            border: 1px solid #212529;
        }

        .swiper-button-next::after,
        .swiper-button-prev::after {
            font-size: 1rem;
            color: rgb(0, 0, 0);
        }
    }

    .ramadancatpics img {
        border-left: 5px solid #ad103b;
        border-right: 5px solid #ad103b;
        border-radius: 10px;
    }

    .ramadancleaning {
        padding-left: 5px;
        padding-right: 5px;

        img {
            border-radius: 10px;
        }

        .col-12 img {
            border-radius: 10px;

        }

        .col-12 {
            padding-right: 8px;
            padding-left: 8px;
        }
    }

    .ramadanhome img {
        border-radius: 10px;
    }

    .left-deal {
        padding-right: 4px;
        padding-left: 8px;
        margin-top: 8px;
    }

    .right-deal {
        padding-right: 8px;
        padding-left: 4px;
        margin-top: 8px;
    }

    .center-deal {
        padding-right: 8px;
        padding-left: 8px;
    }

    .left-deal:first-child {
        margin-top: 0px;
        // padding-right: 7px;
        // padding-left: 12px;
    }

    .right-deal:first-child {
        margin-top: 0px;
        // padding-right: 7px;
        // padding-left: 12px;
    }



    .paintcat {
        background-color: #fcfaf4;
        border: 1px solid #d4982b;
        border-radius: 10px;
    }

    .emptyspacer10 {
        height: 10px;
    }

    .banner-sec img {
        width: 100%;
    }

    background-color: white;

    .row {
        margin: 0px;
    }

    .top-row {
        background-color: #f8f8f8;
    }

    .product-cont {
        display: flex;
        flex-direction: row;
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        overflow-x: auto;
        overflow-y: hidden;

        .image-cont {
            width: 160px;
            max-width: 160px;
            margin-inline-end: 10px;
        }
    }

    .pagesbanner {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    .topofferimg {
        padding: 10px;
        // width: 175px;
        // -webkit-transform: rotate(362deg);
    }

    .offers.catbox {
        padding: 10px;
    }

    .surfacepat .product-list-desktop .product-list-container {

        background-color: #fcfaf4;
    }

    .catrow {
        display: flex;

    }

    .emptyspacer15 {
        height: 15px;
    }

    .prodseccontainer {
        margin-top: 30px;
    }

    .six6 img {
        border: none;
    }



    .padding-10 {
        padding: 10px !important;
    }

    .offerbg {
        background-color: #f8e89a;
        // border-bottom: 140px solid #f8e89a;
        // border-left: 23px solid transparent;
        // border-right: 14px solid transparent;
        border-radius: 5px;
        margin: 0px;
    }

    .shopbycat .col-3 {
        padding: 5px;
    }

    .sectiontitle-individualpage {
        font-size: 20px;
        font-family: 'Futura Heavy';
        text-align: left !important;
        padding-left: 10px;
    }

    .titlewrap.noclr {
        background-color: #ffffff;
    }

    .offertitles {
        text-align: center;
        font-family: 'Futura heavy';
        padding-top: 10px;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 0.9;
        /* -webkit-text-stroke: 1px white; */
        -webkit-text-stroke: thin;
    }

    span.offerprice {
        font-family: 'Futura heavy';
        margin-top: 29px;
        color: #ff5722;
    }

    .mypages-container {
        margin-right: auto !important;
        margin-left: auto !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        width: 1300px;
    }

    @media (min-width: 1360px) {
        .container {
            width: 1300px;
        }
    }

    @media (min-width: 1280px) {
        .container {
            width: 1220px;
        }
    }

    @media (min-width: 1170px) {
        .container {
            width: 1024px;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }

    @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }
}