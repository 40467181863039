@import "../../../mixins.scss";
@import "../../../variables.scss";

.item-overview-desktop.pb-5 {
  @include for-mob-screen {
  padding-bottom: 20px!important;
} }

.item-overview-desktop {
  width: 100%;
  .heading {

    @include for-mob-screen {
    color: #000000;
    font-size: 18px;
    font-family: 'Futura Heavy';
    letter-spacing: 0.3px;
    }
  }

  table.techspecs th {
    background-color: #cccccc87;
}

table.techspecs {
    width: 100%;
}

table.techspecs th, tr, td {
    border: 1px solid black;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

  .center-bar {
    border: 1px solid #d4982b61;
    border-radius: 25%;
    position: absolute;
    left: 50%;
    height: 200px;
    margin-top: 90px;
  }
  .mb-1.innerhtml.video-div {
    padding: 0px!important;
}
  .video-div {
    padding: 25px;
    padding-top: 0px;
  }

  .video-div > iframe {
    width: 100% !important;
    height: 350px !important;
  }

  .innerhtml {
    font-size: 14px;
  }
  .innerhtml p{
    margin-bottom: 0px;
    margin-top: 0px;
  }
  @include for-mob-screen {
    .small-screen-none {
      display: none !important;
    }

    .border-bottom {
      padding-bottom: 14px;
      border-bottom: 1px solid rgb(240, 242, 248);
    }
  }

  @include for-desktop-screen {
    .large-screen-none {
      display: none !important;
    }
  }

  .header-div {
    width: 100%;
    border-bottom: 1px solid rgb(226, 229, 241);

    .text-style {
      color: #858585;
      font-size: 16px;
      cursor: pointer;
      font-weight: 700;
      padding-bottom: 10px;
      &:hover {
        color: #404553;
      }
      margin-inline-start: 25px;
      @include for-mob-screen {
        padding-bottom: 0px;
        margin-inline-start: 12px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .active-tab {
      padding-bottom: 10px;  
      font-size: 16px;
      cursor: pointer;
      border-bottom: 2px solid #d4982b;
    background-color: #742013;
    color: #fff;
    padding: 3px 10px;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg);
    margin-left: 15px!important;
    font-weight: 600;
    letter-spacing: 1px;
      @include for-mob-screen {
        border-bottom: 2px solid #d4982b;
        background-color: #742013;
        color: white;
        font-size: 14px;
        padding: 3px;
        padding-left: 10px;
        padding-right: 10px;
        transform: skewX(-15deg);
      }
    }
   
  }

  .body-div {
    width: 100%;
    text-align: initial;

    .heading-style {
      color: #373737;
      font-size: 15px;
      font-weight: 600;
    }
    .rev .col-12 {
padding-left: 5px;
padding-right: 5px;
    }
    .text-style {
      color: #373737;
      font-size: 13px;
      align-self: flex-start;
    }

    .review-child-one {
      width: 45%;
      border-right: 2px solid rgb(240, 242, 248);
      padding: 0px 10px 0px 0px;
      align-items: center;

      .heading {
        color: #373737;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }

      .rating {
        color: #f09952;
        font-weight: 700;
        font-size: 1.7em;
        text-align: center;
      }

      .rating-star {
        text-align: center;
      }

      .rating-count {
        color: rgb(126, 133, 155);
        font-size: 13px;
        text-align: center;
      }
    }

    .review-child-two {
      width: 55%;
      display: grid;
      grid-template-columns: 20px 20px 20px 1fr 20px;
      grid-template-rows: auto auto auto auto auto;
      margin-left: 8%;
      align-items: center;

      .checkbox {
        color: #c5bdbd;
      }
      .checkbox-checked {
        color: rgb(56, 102, 223);
      }
      .num {
        color: #404553;
        font-size: 12px;
        font-weight: 700;
      }

      .rating-slider {
        background: rgb(242, 242, 242);
        width: 80%;
        height: 8px;
        border-radius: 5px;
        justify-self: center;
      }

      .rating-count {
        color: #404553;
        font-size: 12px;
      }
    }

    .text-div {
      @include for-xxl-screen {
        padding-left: 50px;
      }
      @include for-xl-screen {
        padding-left: 50px;
      }
      @include for-lg2-screen {
        padding-left: 50px;
      }
      @include for-lg1-screen {
        padding-left: 50px;
      }
      @include for-md-screen {
        padding-left: 10px;
      }
      .heading {
        color: #373737;
        font-size: 16px;
        font-weight: 600;
        @include for-xxl-screen {
          font-size: 16px;
        }
        @include for-xl-screen {
          font-size: 16px;
        }
        @include for-lg2-screen {
          font-size: 16px;
        }
        @include for-lg1-screen {
          font-size: 14px;
        }
        @include for-md-screen {
          font-size: 12px;
        }
      }
      .body {
        color: #404553;
        @include for-xxl-screen {
          font-size: 12px;
        }
        @include for-xl-screen {
          font-size: 12px;
        }
        @include for-lg2-screen {
          font-size: 12px;
        }
        @include for-lg1-screen {
          font-size: 10px;
        }
        @include for-md-screen {
          font-size: 10px;
        }
      }
    }

    .rating-div {
      display: flex;
      flex-direction: column;
    }

    .rating-style {
      font-weight: bold;
      font-size: 2.5rem;
    }

    .reviewers-count {
      font-size: 13px;
      color: rgb(189, 189, 189);
    }
    .comments-heading-cont {
      width: 100%;

      .comments-count {
        font-size: 15px;
        color: #373737;
        font-weight: bold;
        display: flex;
        align-items: center;
      }

      .lang-cont {
        background: rgb(243, 244, 248);
        padding: 9px 3px;
        margin-left: 12px;
        border-radius: 6px;

        @include for-mob-screen {
          margin-left: 30px;
        }

        .lang-name {
          font-size: 13px;
          padding: 5px 8px;
          text-align: center;
          align-items: center;
          margin: 0px 3px;
          border-radius: 6px;

          color: #373737;
          font-weight: 600;
          cursor: pointer;
        }

        .active-lang {
          color: rgb(56, 102, 223);
          background: white;
        }
      }
    }

    .comment-body-cont {
      margin: 6px 0px;
      border-top: 2px solid rgb(240, 242, 247);
      padding: 12px 0px;
      display: flex;
      flex-direction: column;

      .comment-header {
        display: flex;
        flex-direction: row;

        .name-avatar {
          width: 48px;
          height: 48px;
          font-weight: 900;
          font-size: 14px;
          color: rgb(66, 171, 68);
          background: rgb(226, 246, 226);
          border-radius: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          align-self: center;
        }

        .user-data {
          display: inline-flex;
          align-items: flex-start;
          margin-left: 12px;

          .name {
            font-size: 15px;
            color: #373737;
            font-weight: 700;
          }

          .date {
            font-size: 13px;
            color: rgb(189, 189, 189);
          }

          .varified-tag {
            border-left: 2px solid rgb(234, 239, 243);
            margin-left: 12px;
            display: inline-flex;
            align-items: center;

            .text {
              color: #373737;
              font-size: 13px;
            }

            .icon {
              color: rgb(56, 102, 223);
              margin: 0px 12px;
            }
          }
        }
      }
      .comment-body {
        margin-top: 12px;
        font-size: 13px;
        color: #404553;
      }
    }
  }
  .display-column {
    flex-direction: column;
  }

  .vendure-text {
    color: #404553;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
  }

  .returns-detail-text {
    color: #7e859b;
    text-align: center;
    font-size: 10px;
  }
}
