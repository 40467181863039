.ramadan-page {
    background-color: white;

    .swiper-container:first-child {
        margin: 15px !important;
        border-radius: 10px;
    }
    .product-list-desktop .swiper-container{
        margin: unset !important;
        border-radius: unset !important;
    }
    .product-card-container-desktop.mb-2 {
        border-radius: 7px;
        border: 1px solid hsla(0, 0%, 80%, .6509803921568628);
        padding: 5px 5px 7px;
    }
    .ramadancatpics img{
        border-left: 5px solid #ad103b;
        border-right: 5px solid #ad103b;
        border-radius: 10px;
    }
    .ramadancatpics .col-md-2{
        padding-left: 8px;
        padding-right: 8px;
    }
    .ramadanhome{
       .col-md-6{
        padding-left: 8px;
        padding-right: 8px;
       } 
       img{
        border-radius: 10px;
       }
    }
    .ramadancleaning{    
    img{
        border-radius: 10px;
    }
    .col-md-12{
        padding-left: 8px;
        padding-right: 8px;
    }
    .col-md-4{
        padding-left: 8px;
        padding-right: 8px;
    }
}
    .swiper-wrapper{
        gap: 10px;
    }
    .pagesbanner {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    .ramadantitle{
    font-family: 'Futura Heavy';
    text-transform: capitalize;
    font-size: 24px;
}

    .paintcat {
        background-color: #fcfaf4;
        border: 1px solid #d4982b;
        border-radius: 10px;
    }

    .paintbrand {
        background-color: #fcfaf4;
        border: 1px solid #d4982b;
        border-radius: 100px;
    }

    .topofferimg {
        width: 175px;
        -webkit-transform: rotate(362deg);
    }

    .offers.catbox {
        padding: 15px;
    }

    .six6.catsecpic img {
        border: 1px solid #9e9e9e52;
    }



    .catrow {
        display: flex;

    }

    .six6 img {
        border: none;
    }

    .catbox {
        padding: 0px;
    }

    .offerbg {
        background-color: #ffffff;
        border-bottom: 140px solid #f8e89a;
        border-left: 23px solid transparent;
        border-right: 14px solid transparent;
        height: 0;
        border-radius: 50px;
        margin-top: 30px !important;
        margin: 15px;
        transform: rotate(2deg);
        -webkit-transform: rotate(358deg);
        -moz-transform: rotate(2deg);
        text-align: right;
    }

    .sectiontitle-individualpage {
        margin-bottom: 0px;
        // text-align: center;
        color: #742013;
        font-weight: 800;
        font-family: 'Futura Heavy';
        font-size: 32px;
    }

    .titlewrap.noclr {
        background-color: #ffffff;
    }

    .offertitles {
        text-align: left;
        float: left;
        width: 170px;
        font-family: 'Futura heavy';
        margin-top: 29px;
        font-size: 35px;
        margin-left: 8PX;
        text-transform: uppercase;
        -webkit-transform: rotate(362deg);
        line-height: 0.9;
        /* -webkit-text-stroke: 1px white; */
        -webkit-text-stroke: thin;
    }

    span.offerprice {
        font-family: 'Futura heavy';
        margin-top: 29px;
        color: #ff5722;
    }

    .mypages-container {
        margin-right: auto !important;
        margin-left: auto !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        max-width: 1300px;
    }

    @media (min-width: 1360px) {
        .container {
            max-width: 1300px;
        }
    }

    @media (min-width: 1280px) {
        .container {
            max-width: 1220px;
        }
    }

    @media (min-width: 1170px) {
        .container {
            max-width: 1024px;
        }
    }

    @media (min-width: 992px) {
        .container {
            max-width: 970px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 750px;
        }
    }
}